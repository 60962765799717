<template>
  <FullScreenDialog
    v-model="customerDialog"
    title="Clienti"
    :routeFather="pathToGoBack"
    @input="$emit('update:open-dialog', $event)"
  >
    <template v-slot:toolbar-extension>
      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab
          v-for="tab in customerTabs"
          :key="tab.key"
        >{{ tab.title }}</v-tab>
      </v-tabs>
    </template>
    <template v-slot:content>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="tabItem in customerTabs" :key="tabItem.key">
          <v-card flat>
            <component 
              :is="tabItem.component"
              :bus="bus"
            ></component>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>

    <template v-slot:footer-actions>
      <div>
        <v-spacer></v-spacer>
        <v-btn
          text
          color="error"
          @click="closeDialog"
        >Annulla</v-btn>
        <v-btn
          text
          color="default"
          @click="submitForm"
          :loading="loading"
        >Salva</v-btn>
      </div>
    </template>

    <template v-slot:header-actions>
      <div>
        <StandardMenu
          close-on-content-click
        >
          <template v-slot:activator="{on, attrs}">
            <v-btn icon v-on="on" v-bind="attrs">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-list>
              <v-list-item link>
                <v-list-item-icon class="mr-3">
                  <v-icon>mdi-printer</v-icon>
                </v-list-item-icon>
                <v-list-item-content>Stampa</v-list-item-content>
              </v-list-item>
              <v-list-item link>
                <v-list-item-icon class="mr-3">
                  <v-icon color="error">mdi-backspace-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="error--text">Resetta</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </StandardMenu>
      </div>
    </template>
  </FullScreenDialog>
</template>

<script>
import Vue from 'vue'
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import StandardMenu from '@/components/common/StandardMenu.vue';
import customerService from '@/services/customers/customers.service.js'
import customerForm from '@/services/customers/customer.form.js'
import * as formComponents from './form'

export default {
  name: "CustomersRegistryForm",
  components: {
    FullScreenDialog,
    StandardMenu,
    ...formComponents
  },
  data: function () {
    return {
      tab: null,
      customerDialog: this.openDialog,
      customerTabs: [
        {
          key: 0,
          title: "Anagrafica",
          name: 'registry',
          component: "RegistryFormTab"
        },
        {
          key: 1,
          title: "Dati Fatturazione",
          name: "invoice",
          component: "InvoiceFormTab",
        },
        {
          key: 2,
          title: "Tessera",
          name: "card",
          component: "CardFormTab",
        },
        {
          key: 3,
          title: "Registro SMS",
          name: "sms",
          component: "SmsFormTab",
        },
/*   keep comment      {
          key: 4,
          title: "Schede Tecniche",
          name: 'dataSheet',
          component: "DataSheetNewFormTab",
        }, */
        {
          key: 5,
          title: "Scheda Parrucchiere",
          name: 'barberDataSheet',
          component: "BarberDataSheet",
        },
        {
          key: 6,
          title: "Scheda Estetica",
          name: 'beautyDataSheet',
          component: "BeautyDataSheet",
        },
      ],
      currentRoute: undefined,
      customer: {...this.precompiled},
      formValid: false,
      originalUrl: window.location.pathname,
      bus: new Vue(),
      loading: false
    };
  },
  mounted() {
    this.changeTabBasedOnProp(this.tabName)
    customerForm.updateObject(this.precompiled)

    let self = this
    customerForm.on('update', function(data) {
      self.customer = data.customer
    })

    self.valid = customerForm.valid
    customerForm.on('valid-change', function(valid) {
      self.formValid = valid
    })
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    pathToGoBack: {
      type: String,
      default: "/registries",
    },
    tabName: {
      type: String,
      default: 'registry'
    },
    precompiled: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    changeTabBasedOnProp(tabName) {
      for(let i = 0; i < this.customerTabs.length; i++) {
        if(this.customerTabs[i].name == tabName) {
          this.tab = i
          break
        }
      }
    },
    submitForm() {
      this.bus.$emit('validate')
      if(!!this.customer.gender) {
        if(this.formValid) {
          this.loading = true
          customerService.create(this.customer).then((result) => {
            this.loading = false
            this.closeDialog()
            this.$emit('submit', result)
          })
        } else {
          this.$delegates.snackbar("Compilare tutti i campi correttamente")
        }
      } else {
        this.$delegates.snackbar("Campo 'Sesso' obbligatorio")
      }
    },
    closeDialog() {
      if(this.pathToGoBack) {
        this.$router.push({path: this.pathToGoBack})
      } else {
        this.customerDialog = false
        this.$emit('update:open-dialog', false)
      }
    }
  },
  watch: {
    precompiled(newVal) {
      customerForm.updateObject(newVal)
    },
    openDialog(newVal) {
      customerForm.updateObject(this.precompiled)
      this.customerDialog = newVal;
    },
    tab(newVal) {
      try {
        window.history.pushState("Tab", "Tab", this.originalUrl + '?tabName=' + this.customerTabs[newVal].name);
      } catch(err) {
        console.log('try setting the tab name but something goes wrong')
      }
    }
  }
};
</script>

<style>
</style>