<template>
  <v-card
    class="mx-auto"
    max-width="344"
    outlined
    v-if="scheduledPayments.length == 0"
  >
    <v-list-item three-line>
      <v-list-item-content>
        <v-btn
          outlined
          rounded
          text
          @click="showSettingPartial = !showSettingPartial"
        > Rateizza </v-btn>
        <div v-if="!!showSettingPartial">
          <v-text-field
            v-model="numberOfPayments"
            label="Numero di Rate"
            type="number"
            filled
            rounded
            dense
            hide-details="auto"
            class="pa-1"
            min="0"
          ></v-text-field>
          <v-text-field
            v-model="daysBetweenPayments"
            label="Giorni tra le rate"
            filled
            type="number"
            dense
            rounded
            class="pa-1"
            min="0"
            hide-details="auto"
            :rules="[(el) => el < 365 || 'Selezionare al massimo 1 anno']"
          ></v-text-field>
          <div class="pa-1">
            <DatePicker
              v-model="expiryFirstPaymentDate"
              filled
              dense
              hide-details="auto"
              prependIcon=""
              append-icon="mdi-calendar"
              label="Scadenza Primo Pagamento"
            ></DatePicker>
          </div>
          <div class="d-flex justify-center">
            <v-btn
              outlined
              class="mt-2 align-center"
              rounded
              text
              :disabled="(!numberOfPayments || !daysBetweenPayments || !expiryFirstPaymentDate) || (!!numberOfPayments && !!daysBetweenPayments && (daysBetweenPayments > 365))"
              @click="implementPartialPayment"
              :loading="loadingButton"
              v-if="!fromNewForm"
            >
              Crea
            </v-btn>
            <v-avatar
              class="mt-1"
              color="green"
              v-else-if="!!fromNewForm && !!schedulingSettings"
            >
              <v-icon>
                mdi-checkbox-marked-circle-outline
              </v-icon>
            </v-avatar>
            <v-avatar
              class="mt-1"
              color="red"
              v-else-if="!!fromNewForm && !schedulingSettings"
            >
              <v-icon>
                mdi-close-circle-outline
              </v-icon>
            </v-avatar>
            
          </div>
        </div>
      </v-list-item-content>
    </v-list-item>
  </v-card>
  <v-stepper v-else-if="scheduledPayments.length > 0" v-model="localStep" class="ma-1">
    <v-stepper-header style="overflow-x: auto;">
      <template v-for="(payment, index) in scheduledPayments">
        <v-stepper-step
          :key="payment.id"
          :complete="payment.status == 'finished'"
          edit-icon="mdi-check"
          editable
          :step="index + 1"
          :color="payment.status == 'finished' ? 'green' : 'red'"
        >
          {{payment.amount + '€ - ' + new Date(payment.expireDate).toLocaleDateString('it')}}
        </v-stepper-step>
        <v-divider :key="payment.expireDate" v-if="scheduledPayments.length < 11 && index < scheduledPayments.length - 1"></v-divider>  
      </template>
    </v-stepper-header>
    <v-stepper-items>
      <v-stepper-content v-for="(payment, index) in scheduledPayments" :key="payment.id" :step="index+1">
        <v-card class="mb-2" color="#385F73">
          <v-card-subtitle style="color: white;">
            Rata n° {{index + 1}}
          </v-card-subtitle>
            <div class="d-flex-wrap justify-center">
              <v-chip class="mx-1">
                Ammontare: {{payment.amount+ '€'}}
              </v-chip>  
              <v-chip class="mx-1">
                Scadenza: {{new Date(payment.expireDate).toLocaleDateString('it')}}
              </v-chip>  
              <v-chip class="mx-1">
                Data Odierna: {{new Date().toLocaleDateString('it')}}
              </v-chip>   
              <v-chip class="mx-1" v-if="!!payment.closedAt">
                Data Pagamento: {{new Date(payment.closedAt).toLocaleDateString('it')}}
              </v-chip>  
              <v-spacer /> 
            </div>
          <v-card-actions>
            <v-spacer/>
            <v-btn 
              v-if="!payment.closedAt" 
              :loading="loadingButton" 
              color="white" 
              @click="pay(payment)" 
              :disabled="!!scheduledPayments[index-1] && scheduledPayments[index-1].status != 'finished'" > Registra Pagamento 
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import DatePicker from '@/components/common/DatePickerCommon.vue'
import invoiceService from "@/services/invoices/invoices.service.js";
import paymentCreditService from '@/services/paymentCredits/paymentCredits.service.js'
export default {
  name: "PartialPayment",
  components: {
    DatePicker,
  },
  data: function () {
    return {
      localStep: 1,
      showSettingPartial: false,
      numberOfPayments: undefined,
      daysBetweenPayments: undefined,
      expiryFirstPaymentDate: undefined,
      loadingButton: false,
      schedulingSettings: undefined,
    };
  },
  props: {
    amount:{
      type: Number,
      default: undefined,
    },
    objectId:{
      type: Number,
      default: undefined,
    },
    type:{
      type: String,
      default: 'invoice',
    },
    scheduledPayments: {
      type: Array,
      default: () => []
    },
    fromNewForm: {
      type: Boolean,
      default: false
    }
  },
  mounted: function () {
    
  },
  methods: {
    /** 
      * @param {Object} paymentCredit - paymentCredit to pay
      * @param {number} paymentCredit.amount   
      * @param {string} paymentCredit.createdAt   
      * @param {string} paymentCredit.expireDate   
      * @param {number} paymentCredit.id   
      * @param {string} paymentCredit.openedAt   
      * @param {string} paymentCredit.type   
      * @param {string} paymentCredit.status   
    */
    async pay(paymentCredit) {
      try {
        let params = {
          date: new Date(),
          amount: Number(paymentCredit.amount),
          otherPaid: Number(paymentCredit.amount),
          documentType: this.type == 'invoice' ? 'invoice' : undefined , // change undefined if you add another type
        }
        this.loadingButton = true
        let result = await paymentCreditService.pay(paymentCredit, params)
        if(!!result && this.quantityPaid() == (this.scheduledPayments.length - 1) && this.type == 'invoice'){
          let invoiceFromDB = await invoiceService.get(this.objectId)
          await invoiceService.update({id: this.objectId, status: !!invoiceFromDB.activeCycle ? 'cashed' : 'paid'})
        }
        this.$emit('paidPartial')
        this.loadingButton = false
      } catch (error) {
        console.log(error)
      }
    },
    /** 
     * Populate an array of objects to be stored in schedule payments
    */
    async implementPartialPayment(){
      try {
        this.localStep = 0
        this.localSteps = Number(this.numberOfPayments)
        let paymentsScheduled = []
        for (let i = 1; i <= this.numberOfPayments; i++){
          let obj = {}

          obj.amount = Number(this.amount/this.numberOfPayments).toFixed(2) 

          if(i==1)
            obj.expireDate = this.expiryFirstPaymentDate
          else{
            obj.expireDate = new Date(paymentsScheduled[i-2].expireDate.getTime())  
            obj.expireDate.setDate(obj.expireDate.getDate() + Number(this.daysBetweenPayments))
          }

          if(!!this.type)
            obj.type = this.type
            
          paymentsScheduled = [...paymentsScheduled, obj]  
        }
        if(this.type == 'invoice' && !!this.objectId){
          this.loadingButton = true
          await invoiceService.schedulePayments(this.objectId, paymentsScheduled)
          this.$emit('schedulePaymentsCreated')  
        }
    
      } catch (error) {
        console.log(error)
      }
    },
    /**
     * Check if all paymentCredits are paid
     */
    quantityPaid(){
      let i = 0
      for (const payment of this.scheduledPayments)
        if (!!payment.closedAt) i++
      return i  
    }
  },
  watch: {
    scheduledPayments(newVal){
      this.loadingButton = false
      if(newVal.length > 0){
        let i
        for(i = 0; i < newVal.length; i++){
          if(!newVal[i].closedAt)
            break
        }
        if(i == newVal.length){
          this.localStep = 1
        }
        else
          this.localStep = i + 1  
      }
    },
    numberOfPayments(newVal){
      if(!!newVal && !!this.daysBetweenPayments && !!this.expiryFirstPaymentDate && this.daysBetweenPayments <= 365)
        this.schedulingSettings = {
          expiryFirstPaymentDate: this.expiryFirstPaymentDate,
          daysBetweenPayments: Number(this.daysBetweenPayments),
          numberOfPayments: Number(this.numberOfPayments),
        }
      else 
        this.schedulingSettings = undefined  
      
    },
    daysBetweenPayments(newVal){
      if(!!newVal && !!this.numberOfPayments && !!this.expiryFirstPaymentDate && this.daysBetweenPayments <= 365)
        this.schedulingSettings = {
          expiryFirstPaymentDate: this.expiryFirstPaymentDate,
          daysBetweenPayments: Number(this.daysBetweenPayments),
          numberOfPayments: Number(this.numberOfPayments),
        }
      else 
        this.schedulingSettings = undefined  
    },
    expiryFirstPaymentDate(newVal){
      if(!!newVal && !!this.daysBetweenPayments && !!this.numberOfPayments && this.daysBetweenPayments <= 365)
        this.schedulingSettings = {
          expiryFirstPaymentDate: this.expiryFirstPaymentDate,
          daysBetweenPayments: Number(this.daysBetweenPayments),
          numberOfPayments: Number(this.numberOfPayments),
        }
      else 
        this.schedulingSettings = undefined  
      
    },
    schedulingSettings(newVal){
      this.$emit('schedulingSettings', newVal)
    }
  },
};
</script>

<style scoped>
.v-stepper__header {
    flex-wrap: nowrap;
}
</style>