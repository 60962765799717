<template>
  <div>
    <StandardDialog
      v-model="itemListDialog"
      persistent
      :title="title"
      dialog-height="calc(90vh-1)"
      dialog-width="80vw"
    >
    <div class="d-flex pa-5" style="width: 100%">
      <AdvancedFilter
        style="width:99%"
        v-model="filtersValue"
        :filters="filterTypes"
        :advance-filters="advanceFilters"
        :external-filter="externalFilter"
        @apply-filters="applyFilters"
      >
        <template v-slot:chip-custom-name="{filter}">
          Descrizione: {{ filter.value }}
        </template>

        <template v-slot:custom-supplierId="{filter}">
          <v-row class="pa-6">
            <v-col>
              <SuppliersAutocomplete
                v-model="filter.value"
                dense
              ></SuppliersAutocomplete>
            </v-col>
          </v-row>
        </template>

        <template v-slot:custom-departmentId="{filter}">
          <v-row class="pa-6">
            <v-col>
              <DepartmentsAutocomplete
                v-model="filter.value"
                dense
                multiple
              ></DepartmentsAutocomplete>
            </v-col>
          </v-row>
        </template>

        <template v-slot:custom-tag="{filter}">
          <v-row class="pa-6">
            <v-col>
              <TagsAutocomplete
                v-model="filter.value"
                dense
                :filled="false"
                multiple
              ></TagsAutocomplete>
            </v-col>
          </v-row>
        </template>

        <template v-slot:custom-unitOfMeasureId="{filter}">
          <v-row class="pa-6">
            <v-col>
              <v-autocomplete
                v-model="filter.value"
                itemText="name"
                itemValue="id"
                :chips="true"
                :deletableChips="true"
                :smallChips="true"
                label="Unità Di Misura"
                :items="unitOfMeasureItems"
                dense
                hide-details="auto"
              ></v-autocomplete> 
            </v-col>
          </v-row>
        </template>

        <template v-slot:custom-packageTypeId="{filter}">
          <v-row class="pa-6">
            <v-col>
              <v-autocomplete
                v-model="filter.value"
                itemText="name"
                itemValue="id"
                :chips="true"
                :deletableChips="true"
                :smallChips="true"
                label="Confezione"
                :items="packageTypeItems"
                dense
                filled
                hide-details="auto"
              ></v-autocomplete> 
            </v-col>
          </v-row>
        </template>

      </AdvancedFilter>  
    </div>  
    <div style="height: 100%; overflow-y: hidden" ref="dataTableWrapper">
        <ItemsDataTable
          :single-select="false"
          :height="dataTableHeight"
          :show-select="true"
          item-key="id"
          fixed-header
          class="mt-10"
          :items="items"
          :headers="headersItem"
          :page.sync="page"
          :row-per-page.sync="rowPerPage"
          :total-pages.sync="totalPages"
          :show-actions="false"
          :translator="translator"
          @input="handleSelection"
        >
          <template v-slot:custom-qtyToSell="{ item }">
            <v-text-field
              type="number"
              @click.stop=""
              dense
              filled
              :rules="[qtyCheck(item['qtyInStock'], item['qtyToSell'])]"
              hide-details="auto"
              not-specified-text="0"
              v-model="item['qtyToSell']"
              @input="checkAddItems"
            > 
            </v-text-field>
          </template>

          <template v-slot:custom-qtyVirtual="{ item }">
            <v-chip color="orange">
              {{
                Number(item['qtyInStock'] ? item['qtyInStock'] : 0) -  Number(item['qtyToSell'] ? item['qtyToSell'] : 0)
              }}</v-chip
            >
          </template>

          <template
            class="justify-center aling-center"
            v-slot:custom-qtyInStock="{ item }"
          >
            <v-chip v-if="item['qtyInStock'] > 0" color="success">
              {{ item["qtyInStock"] }}</v-chip
            >
            <v-chip v-else color="error">
              {{ item["qtyInStock"] ? item["qtyInStock"] : 0 }}</v-chip
            >
          </template>

          <template v-slot:custom-priceSelling="{ item }">
            <v-chip color="white">
              €
              {{ item["priceSelling"] ? item["priceSelling"] : 0 }}</v-chip
            >
          </template>
        </ItemsDataTable>
      </div>

      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn text color="error" @click="close">Chiudi</v-btn>
        <v-btn
          text
          color="default"
          @click="addItems"
          :disabled="loading || addItemValid || itemsToAddSelected.length == 0"
          >Aggiungi</v-btn
        >
      </template>
    </StandardDialog>
  </div>
</template>

<script>
import ItemsDataTable from "@/components/common/TypeDataTable.vue";
import StandardDialog from "@/components/common/StandardDialog";
import itemService from "@/services/warehouse/items.service.js";
import itemVocabulary from "@/vocabulary/warehouse/item.vocabulary.js";
import AdvancedFilter from "@/components/common/AdvancedFilter.vue";
import _itemListFilter from "@/components/areas/warehouse/items/filters/itemList";

export default {
  name: "ItemListDialog",
  components: {
    AdvancedFilter,
    ItemsDataTable,
    StandardDialog,
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    otherItemList: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "Aggiungi prodotti in fattura",
    },
  },
  mounted() {
    this.itemListFilter = _itemListFilter;
    this.fetchItems();
  },

  data() {
    return {
      itemListFilter: [], 
      itemListDialog: this.openDialog,
      dataTableHeightValue: 400,
      addItemValid: true,
      items: [],
      itemsToAddSelected: [],
      loading: false,
      page: 1,
      rowPerPage: 100,
      totalPages: 1,
      qtyCheck: (qtyInStock, qtyToSell) => {
        if (Number(qtyToSell) <= 0) 
          return itemVocabulary.Correct_Qty.desc;
        else if (Number(qtyToSell) > Number(qtyInStock))
          return itemVocabulary.Qty_Not_Enough.desc;
        else return true;
      },
      headersItem: [
        {
          text: "Fornitore",
          value: "supplier",
          type: "object",
          style: "chip",
        },
        {
          text: "Codice Interno",
          value: "internalCode",
        },
        {
          text: "Barcode",
          value: "code",
        },
        {
          text: "Descrizione",
          value: "description",
        },

        {
          text: "Quantità a magazzino",
          value: "qtyInStock",
          type: "custom",
        },
        {
          text: "Quantità virtuale",
          value: "qtyVirtual",
          type: "custom",
        },
        {
          text: "Quantità da vendere",
          value: "qtyToSell",
          type: "custom",
        },
        {
          text: "Prezzo di vendita",
          value: "priceSelling",
          type: "custom",
        },
      ],
      filterTypes: [
        { type: 'date', operator: 'between', field: 'createdAt', name: 'Data Creazione', label: ['Da', 'A'], color: "", value: [undefined, undefined] },
        { type: 'date', operator: 'between', field: 'lastPurchaseDate', name: 'Data Ultimo Acquisto', label: ['Da', 'A'], color: "", value: [undefined, undefined] },
        { type: 'custom', operator: 'equal', field: 'supplierId', name: 'Fornitore', label: 'Fornitore', color: "", value: [], icon: "mdi-dolly" },
        { type: 'string', operator: 'equal', field: 'items.code', name: 'Barcode', label: 'Barcode', color:"", value: undefined, icon: "mdi-barcode" },
        { type: 'cash', operator: 'equal', field: 'priceSelling', name: 'Prezzo di vendita', label: 'Prezzo di vendita', color: "", value: undefined },
        { type: 'cash', operator: 'equal', field: 'priceNetPurchase', name: "Prezzo d'acquisto", label: "Prezzo d'acquisto", color: "", value: undefined },
        { type: 'cash', operator: 'equal', field: 'priceNetPurchase', name: "Prezzo Netto d'acquisto", label: "Prezzo Netto d'acquisto", color: "", value: undefined },
        { type: 'custom', operator: 'custom', field: 'tag', name: 'Tag Famiglia', label: 'Tag', color: "", value: undefined, icon: 'mdi-tag' },
        { type: 'custom', operator: 'custom', field: 'tagCategory', name: 'Categoria Tag', label: 'Categoria Tag', color: "", value: undefined, icon: 'mdi-tag-multiple' },
      ],
      advanceFilters: [
        { type: 'custom', operator: 'custom', field: 'departmentId', name: 'Reparto', label: 'Reparto', color: "", value: [], icon: "mdi-home-city" },
        { type: 'number', operator: 'equal', field: 'discount', name: 'Sconto', label: 'Sconto', color: "", value: undefined, icon: "mdi-sale" },
        { type: 'boolean', operator: 'equal', field: 'isInternal', name: 'Uso Interno', label: 'Uso Interno', color: "", value: false },
        { type: 'custom', operator: 'equal', field: 'packageTypeId', name: 'Confezione', label: 'Confezione', color: "", value: undefined, icon: "mdi-package-variant" },
        { type: 'custom', operator: 'equal', field: 'unitOfMeasureId', name: 'Unità di misura', label: 'Unità di misura', color: "", value: undefined, icon: "mdi-ruler-square-compass" },
      ],
      externalFilter: { type: 'custom', operator: 'custom', field: 'name', name: 'Nome', label: 'Prodotto', color: "", value: "" },
      filtersValue: [
      ],
    };
  },
  computed: {
    dataTableHeight() {
      return this.dataTableHeightValue + "px";
    },
  },
  methods: {
      fetchItems() {
      this.loading = true;
      let filters = [...this.filtersValue]

      itemService.list(this.page, this.rowPerPage, filters).then((results) => {
        if (this.rowPerPage != results.rowPerPage) {
          this.rowPerPage = results.rowPerPage;
        }

        if (this.page != results.page) {
          this.page = results.page;
        }

        this.totalPages = results.totalPages;
        if (this.totalPages < this.page) {
          this.page = this.totalPages;
        }
        this.items = results.rows;
        this.loading = false;
      });
    },
    translator(field, value) {
      if (field == "itemType" && value) {
        return value.name;
      } else if (field == "supplier" && value) {
        return value.businessName;
      }
    },
    handleSelection(itemsToAddSelected) {
      this.checkAddItems();
      this.itemsToAddSelected = itemsToAddSelected;
    },
    checkAddItems() {
      this.addItemValid = this.itemsToAddSelected.some((itemToAdd) => {
        return Number(itemToAdd.qtyToSell) < 1 || Number(itemToAdd.qtyToSell) > Number(itemToAdd.qtyInStock) || !Number(itemToAdd.qtyToSell);
      });
    },
    addItems() {
      if(!this.checkAddItems()) {
        this.$emit('add-items', this.itemsToAddSelected)
        this.itemsToAddSelected = []
      } else this.$emit('error-quantity', 'Inserire il numero corretto di quantità per proseguire') 
    },
    close() {
      this.itemListDialog = false;
      this.$emit("close");
    },
    applyFilters(filters) {
      this.fetchItems();
    },
  },
  watch: {
    page() {
      this.fetchItems();
    },
    rowPerPage() {
      this.fetchItems();
    },
    openDialog(newVal) {
      this.itemListDialog = newVal;
    },
        itemListFilter(newVal) {
      this.itemListFilter = newVal;
    },
  },
};
</script>

<style>
</style>