<template>
  <FullScreenDialog v-model="customerDialog"
    :title="!!customer && !!customer.firstname && !!customer.lastname ? customer.firstname + ' ' + customer.lastname : 'Modifica cliente'"
    :routeFather="pathToGoBack" 
    :canClose="canClose && !loadingCustomer" 
    :routeFatherName="pathName"
    :otherGoBackParams="{ params: { filters: this.filters } }"
  >
    <template v-slot:header-actions>
      <v-btn 
        icon 
        @click="toggleBarcode"
        :color="!!canViewGhost ? 'red' : ''"
        :disabled="loadingCustomer"
      >
        <v-icon>mdi-shield-account-variant</v-icon>
      </v-btn>
    </template>

    <template v-slot:toolbar-extension>
      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab 
          v-for="tab in customerTabs" 
          :key="tab.key"
          :disabled="loadingCustomer"
          >{{ tab.title }}</v-tab>
      </v-tabs>
    </template>

    <template #content>
      <div v-if="loadingCustomer" class="text-center mt-2">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <v-tabs-items v-else v-model="tab">
        <v-tab-item v-for="tabItem in customerTabs" :key="tabItem.key" eager>
          <v-card flat>
            <component 
              :is="tabItem.component" 
              :can-view-ghost="canViewGhost"
              :tab-name="barberDataSheetTabName"
            >
            </component>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>

    <template v-slot:footer-actions>
      <div>
        <v-spacer></v-spacer>
        <v-btn
        text
        color="error"
        :disabled="loadingCustomer"
        @click="closeDialog()"
        >Annulla</v-btn>
        <v-btn
        text
        color="default"
        :disabled="!formValid || isLicenseBlocked || loadingCustomer"
        @click="submitForm"
        >Salva</v-btn>
      </div>
      <StandardDialog
        v-model="dialogBarcode"
        title="Area Riservata Sviluppatori" 
        :dialog-height="null" 
        dialog-max-width="500px"
      >
        <ManualBarcodeInput
          v-model="ghostBarcode"
          scan-your-barcode-message="Immettere Codice" 
          @confirm="activeGhost"
        >
        </ManualBarcodeInput>
      </StandardDialog>
    </template>
  </FullScreenDialog>
</template>

<script>
import customerForm from '@/services/customers/customer.form.js'
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import operatorService from '@/services/operators/operators.service.js'
import customerService from '@/services/customers/customers.service.js'
import * as formComponents from './form'
import ManualBarcodeInput from '@/components/common/ManualBarcodeInput.vue';
import StandardDialog from '@/components/common/StandardDialog.vue';

export default {
  name: "CustomersRegistryEditForm",
  components: {
    FullScreenDialog,
    ManualBarcodeInput,
    StandardDialog,
    ...formComponents
  },
  data: function () {
    return {
      tab: null,
      customerDialog: this.openDialog,
      customerTabs: [
        {
          key: 0,
          title: "Anagrafica",
          name: 'registry',
          component: "RegistryFormTab"
        },
        {
          key: 1,
          title: "Dati Fatturazione",
          name: "invoice",
          component: "InvoiceFormTab",
        },
        {
          key: 5,
          title: "Tessera",
          name: "card",
          component: "CardFormTab",
        },
        {
          key: 2,
          title: "Registro SMS",
          name: "sms",
          component: "SmsFormTab",
        },
        /*   keep comment      {
                  key: 4,
                  title: "Schede Tecniche",
                  name: 'dataSheet',
                  component: "DataSheetEditFormTab",
                }, */
        {
          key: 3,
          title: "Diario",
          name: 'diary',
          component: "DiaryFormTab",
        },
        {
          key: 4,
          title: "Promo",
          name: 'promo',
          component: "PromoFormTab",
        },
        {
          key: 6,
          title: "Scheda Parrucchiere",
          name: 'barberDataSheet',
          component: "BarberDataSheet",
        },
        {
          key: 7,
          title: "Scheda Estetica",
          name: 'beautyDataSheet',
          component: "BeautyDataSheet",
        },
      ],
      currentRoute: undefined,
      formValid: true,
      customer: undefined,
      originalUrl: window.location.pathname,
      dialogBarcode: false,
      ghostBarcode: undefined,
      canViewGhost: false,
      isLicenseBlocked: undefined,
      loadingCustomer: false
    };
  },
  mounted() {
    this.changeTabBasedOnProp(this.tabName)
    this.fetchCustomer()

    let self = this
    customerForm.on('update', function (data) {
      self.customer = data.customer
    })

    self.valid = customerForm.valid
    customerForm.on('valid-change', function (valid) {
      self.formValid = valid
    })

  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    pathToGoBack: {
      type: String,
      default: "/registries",
    },
    pathName: {
      type: String,
    },
    tabName: {
      type: String,
      default: 'registry'
    },
    barberDataSheetTabName: {
      type: String,
      default: 'barberInfo'
    },
    filters: {
      type: Array,
    },
    customerId: {
      type: String | Number
    },
    canClose: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    changeTabBasedOnProp(tabName) {
      for (let i = 0; i < this.customerTabs.length; i++) {
        if (this.customerTabs[i].name == tabName) {
          this.tab = i
          break
        }
      }
    },
    submitForm() {
      customerService.update(this.customer).then((result) => {
        this.$emit('submit', result)
        if (!!this.pathToGoBack) {
          this.close()
        }
      })
    },
    fetchCustomer() {
      this.loadingCustomer = true

      customerForm.resetCustomer()
      customerService.get(this.customerId).then((customer) => {
        this.loadingCustomer = false
        customerForm.updateObject(customer)
      })
    },
    closeDialog() {
      if (this.pathToGoBack) {
        this.close()
      } else {
        this.customerDialog = false
        this.$emit('update:open-dialog', false)
      }
    },
    close() {
      if(!!this.pathName)
        this.$router.push({name: this.pathName, params: { filters: this.filters }})
      else
        this.$router.push({path:this.pathToGoBack})
    },
    toggleBarcode(){
      this.dialogBarcode = true
    },
    activeGhost(){
      operatorService.canPerformOperation(this.ghostBarcode, "Ghost").then((result) => {
        if (result) {
          this.canViewGhost = !this.canViewGhost
        } else {
          alert("Non hai i permessi per eseguire questa azione!")
        }
      }).catch(() => {
        alert("Badge non riconosciuto")
      })
      this.ghostBarcode = undefined
      this.dialogBarcode = false
    },
  },
  watch: {
    openDialog(newVal) {
      this.customerDialog = newVal;
    },
    customerId(newVal) {
      this.fetchCustomer()
    },
    tab(newVal) {
      try {
        if (!!this.pathToGoBack) {
          window.history.pushState("Tab", "Tab", this.originalUrl + '?tabName=' + this.customerTabs[newVal].name);
        }
      } catch (err) {
        console.log('try setting the tab name but something goes wrong')
      }
    }
  },
  computed: {

  },
  isLicenseBlocked: {
    bind: 'isLicenseBlocked'
  }
};
</script>

<style>

</style>