<template>
  <div>
    <AjaxAutocomplete
      v-model="selected"
      :searcher="searchCustomer"
      @input="handleInput"
      filled
      :item-text="(entry) => { return entry.lastname + ' ' + entry.firstname}"
      item-value="id"
      hide-details="auto"
      :search-threshold="1"
      :return-object="returnObject"
      :value-searcher="searchById"
      :loading="loading"
      :prepend-icon="prependIcon"
      :append-outer-icon="appendOuterIcon"
      :append-icon="appendIcon"
      :clearable="clearable"
      :label="label"
      @click:prepend="$emit('click:prepend', $event)"
      @click:append-outer="handlePencilClick"
      :focus-text="focusText"
      :disabled="disabled"
      :external-search="externalSearch"
    ></AjaxAutocomplete>
    <CustomerNewForm
      v-if="hasToOpenNewForm"
      :open-dialog.sync="customerNewFormDialog"
      :path-to-go-back="null"
      :precompiled="precompiledCustomer"
      @submit="handleFormSubmit"
    ></CustomerNewForm>
    <CustomerEditForm
      :open-dialog.sync="customerEditFormDialog"
      :path-to-go-back="null"
      :can-close="false"
      :customer-id="!!selected ? selected.id : undefined"
      @submit="handleEditFormSubmit">
    </CustomerEditForm>

  </div>
</template>

<script>
import AjaxAutocomplete from '@/components/common/AjaxAutocomplete.vue'
import customerService from '@/services/customers/customers.service.js'
import CustomerNewForm from '@/components/areas/registries/customers/CustomersRegistryNewForm.vue'
import CustomerEditForm from '@/components/areas/registries/customers/CustomersRegistryEditForm.vue'

export default {
  name: "CustomerAutocomplete",
  components: {
    AjaxAutocomplete,
    CustomerNewForm,
    CustomerEditForm
  },
  data: function() {
    return {
      autoDetectNewCustomerSetting: undefined,
      selected: this.returnObject ? {...this.value} : this.value,
      customerEditFormDialog: false,
      customerNewFormDialog: false,
      precompiledCustomer: {}
    }
  },
  props: {
    focusText: {
      type: Boolean,
      default: false
    },
    value: {},
    returnObject: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    autoDetectNewCustomer: {
      type: Boolean,
      default: true
    },
    prependIcon: {
      type: String,
      default: undefined
    },
    appendOuterIcon: {
      type: String,
      default: undefined
    },
    appendIcon: {
      type: String,
      default: undefined
    },
    clearable: {
      type: Boolean, 
      default: true
    },
    label: {
      type: String,
      default: 'Inserisci cognome ...'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    externalSearch: {
      type: String,
      default: undefined
    }
  },
  methods: {
    searchCustomer(searchText) {
      return new Promise((resolve, reject) => {
        customerService.search(searchText).then((results) => {
          if(this.hasToOpenNewForm && results.length == 0) {
            this.precompiledCustomer = {
              lastname: searchText.split(' ')[0],
              firstname: searchText.split(' ')[1],
              prefixCellphone: "+39"
            }
            this.customerNewFormDialog = true
          }
          resolve(results)
        })
      })
    },
    handleInput() {
      this.$emit('input', this.selected)
    },
    searchById(customer) {
      return customerService.get(customer.id)
    },
    async handleFormSubmit(customer) {
      if(this.returnObject) {
        let newCustomer = await customerService.get(customer.id)
        this.selected = {...newCustomer}
      } else {
        this.selected = customer.id
      }
      this.$emit('input', this.selected)
    },
    handleEditFormSubmit(customer) {
      if(this.returnObject) {
        this.selected = {...customer}
      } else {
        this.selected = customer.id
      }
      this.$emit('input', this.selected)
      this.customerEditFormDialog = false
    },
    handlePencilClick() {
      this.customerEditFormDialog = true
    }
  },
  computed: {
    hasToOpenNewForm() {
      return this.autoDetectNewCustomer && this.autoDetectNewCustomerSetting
    }
  },
  watch: {
    value(newVal) {
      this.selected = newVal
    }
  },
  settings: {
    autoDetectNewCustomer: {
      bind: 'autoDetectNewCustomerSetting'
    }
  }
}
</script>

<style>

</style>