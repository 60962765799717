<template>
  <div>
    <BorderedDiv small description="Totale ante sconto">
      <template v-slot:content="{}">
        <h3>€ {{ amountInvoice }}</h3>
      </template>
    </BorderedDiv>
    <BorderedDiv small description="Sconti Applicati">
      <template v-slot:content="{}">
        <h3>€ {{ discountForElements }}</h3>
      </template>
    </BorderedDiv>

    <BorderedDiv small description="Imponibile">
      <template v-slot:content="{}">
        <h3>€ {{ taxable }}</h3>
      </template>
    </BorderedDiv>
    <BorderedDiv small description="IVA">
      <template v-slot:content="{}">
        <h3>€ {{ VATAmount}}</h3>
      </template>
    </BorderedDiv>

    <v-divider class="my-4"></v-divider>
    <BorderedDiv small description="Totale (con IVA)">
      <template v-slot:content="{}">
        <h3>€ {{ finalTotalWithVAT }}</h3>
      </template>
    </BorderedDiv>
  </div>
</template>

<script>
import invoiceForm from "@/services/invoices/invoice.form.js";
import Vue from "vue";
import BorderedDiv from "@/components/common/BorderedDivComponent.vue";
import TaxesListSelector from "@/components/areas/payments/taxes/TaxesListSelector.vue"
import invoiceService from "@/services/invoices/invoices.service.js";

export default {
  name: "InvoiceSummary",
  components: {
    BorderedDiv,
    TaxesListSelector,
  },
  data() {
    return {
      extraDiscount: Number(0).toFixed(2),
    };
  },
  props: {
    invoice: {
      type: Object,
      deafult: () => {},
    },
    variant:{
      type: String,
      default: 'new'
    },
    vatPercentageEdit:{
      type: Number,
      default: undefined
    },
    bus:{
      type: Object,
      default: () => new Vue()
    },
    mode:{
      type: String,
      default: 'new'
    }
  },
  mounted() {
    //let self = this
    //this.bus.$on('calcVAtTotal',() => self.calcVatTotal());
  },
  methods: {
    updateObject(key, value) {
      invoiceForm.updateField(key, value);
    },
  },
  computed: {
    amountInvoice() {
      if (!!this.invoice && this.invoice.invoiceElements.length > 0) {
        let elements = this.invoice.invoiceElements;
        let totalAmountForElements = 0;
        elements.map((el) => {
          totalAmountForElements += el.price * el.quantity;
        });
        return Number(totalAmountForElements).toFixed(2);
      }
      return Number(0).toFixed(2);
    },
    discountForElements() {
      if (!!this.invoice && this.invoice.invoiceElements.length > 0) {
        let elements = this.invoice.invoiceElements;
        let totalDiscountForElements = 0;
        elements.map((el) => {
          totalDiscountForElements +=
            el.price * el.quantity -
            (el.discount ? ((el.price * el.discount) / 100) * el.quantity : 0);
        });
        return Number(
          (Number(this.amountInvoice) - Number(totalDiscountForElements))
        ).toFixed(2);
      }
      return (0).toFixed(2);
    },
    taxable() {
      let res = this.invoice.invoiceElements.reduce((acc, item) => {
        return acc + Number(
          Number(item["price"]) *
            ((100 - Number(item["discount"])) / 100)
            / ((100 + Number(item["vatPercentage"])) / 100)
            * Number(item["quantity"])
        )
      }, 0).toFixed(2);
      this.updateObject('taxable', Number(res))
      return res
    },
    VATAmount(){
      let res = this.invoice.invoiceElements.reduce((acc, item) => {
        return acc + Number(
          Number(item["price"]) *
            ((100 - Number(item["discount"])) / 100)
            / ((100 + Number(item["vatPercentage"])) / 100)
            * (Number(item["vatPercentage"]) / 100)
            * Number(item["quantity"])
        )
      }, 0).toFixed(2);
      this.updateObject('vatAmount', Number(res))
      return res
    },
    finalTotalWithVAT() {
      if (!!this.invoice && this.invoice.invoiceElements.length > 0) {
        let elements = this.invoice.invoiceElements;
        let totalDiscountForElements = 0;
        elements.map((el) => {
          totalDiscountForElements +=
            el.price * el.quantity -
            (el.discount ? ((el.price * el.discount) / 100) * el.quantity : 0);
        });
        this.updateObject('amount', Number(Number(totalDiscountForElements).toFixed(2)))
        return Number(totalDiscountForElements).toFixed(2);
      }
      this.updateObject('amount', Number(0))
      return Number(0).toFixed(2);
    },
    updateAllFields(){
      this.updateObject('taxable', Number(this.taxable))
      this.updateObject('amount', Number(this.finalTotalWithVAT))
      this.updateObject('vatAmount', Number(this.VATAmount))
    }
  },
  watch :{
  }
};
</script>

<style>
</style>